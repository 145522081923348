import React from "react";

const useProjectInfo = (info, showInfo) => {
    if (!showInfo | !info)
        return <></>;

    return (<>
        <div className="project-info-details">
            {info.location && <p><span>Location</span>{info.location}</p>}
            {info.year && <p><span>Year</span>{info.year}</p>}
            {info.type && <p><span>Type</span>{info.type}</p>}
            {info.status && <p><span>Status</span>{info.status}</p>}
            {info.client && <p><span>Client</span>{info.client}</p>}
            {info.area && <p><span>Area</span>{info.area}</p>}
            {info.cost && <p><span>Cost</span>{info.cost}</p>}
        </div>
        {info.projectTeam && info.projectTeam.length > 0 &&
            <div className="project-architects">
                <p>Project team</p>
                {info.projectTeam.map((architect, index) => {
                    return (<p key={index} className="project-architect">{architect.teamMember}</p>);
                })
                }
            </div>
        }
        {info.photosBy && info.photosBy.length > 0 &&
            <div className="project-photographers">
                <p>Photos</p>
                {info.photosBy.map((photosBy, index) => {
                    return (<p key={index} className="project-photographer">{photosBy.photographer}</p>);
                })
                }
            </div>
        }
        {info.rendersBy && info.rendersBy.length > 0 &&
            <div className="project-renders-authors">
                <p>3D images</p>
                {info.rendersBy.map((rendersBy, index) => {
                    return (<p key={index} className="project-renders-author">{rendersBy.rendersAuthor}</p>);
                })
                }
            </div>
        }
    </>);
}

export default useProjectInfo;