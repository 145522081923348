import React from "react";
import useProjectInfo from "../customHooks/useProjectInfo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const MobileContentColumn = ({ content }) => {
    return (
        <div className="columns layout-margin-top">
            <div className="mobile-project-container">
                <p className="center-text">{content.name}</p>
            </div>
            {content.projectPhotos?.map((projectPhoto, index) => {
                return (
                    <div key={"projectPhotograph" + index} className="mobile-project-container">
                        <GatsbyImage
                            image={getImage(projectPhoto.photo)}
                            alt={content.name + " | photograph " + (index + 1)}
                            className='mobile-project-photo'
                            objectFit="contain"
                            loading="eager"
                        />
                    </div>
                );
            })
            }
            {<div className="mobile-project-container mobile-project-info">{useProjectInfo(content, true)}</div>}
            <div className="mobile-project-container mobile-press-release">
                {content.pressRelease?.map((entry, index) => {
                    return <p key={"pressRelease" + index}>{entry.paragraph}</p>
                })}
            </div>
            {
                content.technicalDrawings?.map((technicalDrawing, index) => {
                    return (
                        <div key={"technicalDrawing" + index} className="mobile-project-container">
                            <GatsbyImage
                                image={getImage(technicalDrawing.drawing)}
                                alt={content.name + " | technical drawing " + (index + 1)}
                                className="technical-drawing"
                                objectFit="contain"
                                loading="eager"
                            />
                        </div>
                    );
                })
            }
        </div>
    )
};

export default MobileContentColumn;