import React from "react";
import { graphql } from "gatsby";
import CenteredTitle from "../components/CenteredTitle";
import DesktopContentCarousel from "../components/project/DesktopContentCarousel";
import MobileContentColumn from "../components/project/MobileContentColumn";
import LayoutArraiolos from "../components/LayoutArraiolos";
import { useIsMobile } from "../components/helpers/useIsMobile";
import useProjectInfo from "../components/customHooks/useProjectInfo";
import PageTitles from "../components/helpers/pageTitles";
import { SEO } from "../components/Seo";

const ProjectInfo = ({ info }) => {
    const [showInfo, setShowInfo] = React.useState(false);

    const projectInfo = useProjectInfo(info, showInfo);

    return (
        <div className="project-info layout-padding-top">
            <div id="project-info-toggle"><a href="#" id="project-info-toggle-anchor" onClick={() => setShowInfo(!showInfo)}>info</a></div>
            {projectInfo}
        </div>
    );
};

const Project = ({ data }) => {
    const [showprojectName, setShowProjectName] = React.useState(true);

    const isMobile = useIsMobile();

    const handleFullScreenClicked = () => {
        setShowProjectName(false);
    }

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setShowProjectName(false);
        }, 1750);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {showprojectName &&
                <div className='columns fade-in-quick-out-slow project-title' onClick={handleFullScreenClicked}>
                    <CenteredTitle size="is-size-2">{data.markdownRemark.frontmatter.name}</CenteredTitle>
                </div>
            }
            {!showprojectName &&
                <LayoutArraiolos pageTitle={data.markdownRemark.frontmatter.name + " | " + PageTitles.HOMEPAGE} sideContent={!isMobile && <ProjectInfo info={data.markdownRemark.frontmatter} />}>
                    <div id="project-main" className='column'>
                        <div className='columns is-vcentered layout-padding' id="project-main-content">
                            <div className="column is-justify-content-center is-flex is-hidden-mobile" id="gallery">
                                <DesktopContentCarousel content={data.markdownRemark.frontmatter} />
                            </div>
                            <div className="column is-justify-content-center is-flex is-hidden-tablet" id="mobile-project">
                                <MobileContentColumn content={data.markdownRemark.frontmatter} />
                            </div>
                        </div>
                    </div>
                </LayoutArraiolos>
            }
        </>
    );
};

export default Project;

export const Head = ({ data }) => (
    <SEO title={`${PageTitles.HOMEPAGE} | ${data.markdownRemark.frontmatter.name}`} image={data.markdownRemark.frontmatter.coverPhoto.absolutePath} />
);

export const ProjectQuery = graphql`
query ProjectPage($id: String!) {
    markdownRemark(id: {eq: $id}) {
        frontmatter {
            name
            coverPhoto {
                absolutePath
            }
            location
            year
            type
            status
            client
            area
            cost
            projectTeam {
                teamMember
            }
            photosBy {
                photographer
            }
            rendersBy {
                rendersAuthor
            }
            technicalDrawings {
                drawing {childImageSharp {gatsbyImageData(quality: 100)} }
            }
            pressRelease {
                paragraph
            }
            projectPhotos {
                photo {childImageSharp {gatsbyImageData(quality: 100)} }
            }
        }
    }
}
`;