import React from "react";

const useArrowPressed = (callback) => {
    const ref = React.useRef();

    React.useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "ArrowLeft" || e.key === "ArrowRight")
                callback(e.key);
        };

        document.addEventListener('keydown', handleKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleKeyDown, true);
        };
    }, [ref, callback]);

    return ref;
};

export default useArrowPressed;