import React from "react";
import useOutsideClick from "../helpers/useOutsideClick";
import useArrowPressed from "../helpers/useArrowPressed";
import { GatsbyImage, getImage } from "gatsby-plugin-image";


const DesktopContentCarousel = ({ content }) => {
    const { name, projectPhotos, pressRelease, technicalDrawings } = content;
    const totalPictures = projectPhotos?.length;

    const [prevSlideIndex, setPrevSlideIndex] = React.useState(-1);
    const [slideIndex, setSlideIndex] = React.useState(0);
    const [next, setNext] = React.useState(false);
    const [clicked, setClicked] = React.useState(false);

    const totalSlideItems = React.useMemo(() => {
        const totalPhotos = content.projectPhotos?.length || 0;
        const totalDrawings = content.technicalDrawings?.length || 0;
        const pressRelease = content.pressRelease?.length > 0 ? 1 : 0;

        return totalPhotos + totalDrawings + pressRelease;
    }, [content])

    React.useEffect(() => {
        if (next && clicked) {
            setPrevSlideIndex(slideIndex);
            changeSlide(slideIndex + 1);
        }
        else if (!next && clicked) {
            setPrevSlideIndex(slideIndex);
            changeSlide(slideIndex - 1);
        }
        setClicked(false);

    }, [clicked])


    const changeSlide = (newIndex) => {
        if (newIndex >= totalSlideItems)
            setSlideIndex(0);
        else if (newIndex < 0)
            setSlideIndex(totalSlideItems - 1)
        else
            setSlideIndex(newIndex);
    }

    const handleClickOutside = (e) => {
        if (e.x < (e.view.innerWidth / 2)) {
            setNext(false);
            setClicked(true);
        } else {
            setNext(true);
            setClicked(true);
        }
    };

    const handleArrowPressed = (keyPressed) => {
        if (keyPressed === "ArrowLeft") {
            setNext(false);
            setClicked(true);
        } else if (keyPressed === "ArrowRight") {
            setNext(true);
            setClicked(true);
        }
    };

    const refMouse = useOutsideClick(handleClickOutside, ["project-info-toggle-anchor"]);
    const refArrow = useArrowPressed(handleArrowPressed);

    const getSlideStateClassName = (index) => {
        if (index === slideIndex)
            return "active";
        else if (index === prevSlideIndex)
            return "become-inactive";
        else
            return "inactive";
    };

    return (
        <>
            {projectPhotos?.map((projectPhoto, index) => {
                return (
                    <div key={index} className={`carousel-slide project-container ${getSlideStateClassName(index)}`}>
                        <GatsbyImage
                            image={getImage(projectPhoto.photo)}
                            alt={name + " | photograph " + (index + 1)}
                            className='project-photo'
                            objectFit="contain"
                        />
                    </div>
                );
            })
            }
            <div key={totalPictures} className={`carousel-slide project-container press-release ${getSlideStateClassName(totalPictures)}`}>
                {pressRelease?.map((entry, index) => {
                    return <p key={"pressRelease" + index}>{entry.paragraph}</p>
                })}
            </div>
            {technicalDrawings?.map((technicalDrawing, index) => {
                return (
                    <div key={index + totalPictures + 1} className={`carousel-slide project-container ${getSlideStateClassName(totalPictures + index + 1)}`}>
                        <GatsbyImage
                            image={getImage(technicalDrawing.drawing)}
                            alt={name + " | technical drawing " + (index + 1)}
                            className="technical-drawing"
                            objectFit="contain"
                        />
                    </div>
                );
            })
            }
            <div key={name} className="project-name-bottom is-hidden-mobile">
                <p>{name}</p>
            </div>
            <div ref={refArrow} />
            <div ref={refMouse} />
        </>
    )
};

export default DesktopContentCarousel;