import React from "react";

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = React.useState(true);

    React.useEffect(() => {
        setIsMobile(window.innerWidth <= 768)
    }, []);

    React.useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return isMobile;
}

export const useIsDesktop = () => {
    const [isDesktop, setIsDesktop] = React.useState(true);

    React.useEffect(() => {
        setIsDesktop(window.innerWidth > 768)
    }, []);

    React.useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return isDesktop;
}